import React from "react"
import {
    HeroAngleRightImage as Hero,
    LogoCloudSimpleBranded as LogoCloud,
    FeatureAlternatingWithTestimonial as Feature,
    FeatureList as FeatureList,
    StatsCard as Stats,
    ContactSimpleTwoColumns as Contact
} from "@domenicosolazzo/site-designsystem"
import Layout from "../components/Layouts/Layout"
import { Error404, DataLinks} from "../data/index"

export default function PortfolioPage() {
    return(
        <Layout>
            <Hero />
            <Stats />
            <Feature />
            <LogoCloud />
            <FeatureList />
            <Contact />
        </Layout>
    )
}